import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteReusableStrategy, ApiPrefixInterceptor, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminModule } from './areas/admin/admin.module';
import { BillingModule } from './areas/billing/billing.module';
import { DashboardModule } from './areas/dashboard/dashboard.module';
import { LoyaltyProgramModule } from './areas/loyalty-program/loyalty-program.module';
import { MembersModule } from './areas/members/members.module';
import { MerchantsModule } from './areas/merchants/merchants.module';
import { ChangePasswordModule } from './areas/change-password/change-password.module';
import { ToastModule } from 'primeng/toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigurationService } from '@ep/shared';
import assetCacheBusting from '../../asset-cache-busting.json';

function getEnvironment(): string {
  switch (location.origin) {
    case 'http://localhost:4200':
      return 'staging';
    case 'https://staging-coalition.enrollandpay.com':
    case 'https://nabagent-staging.enrollandpay.com':
      return 'staging';
    case 'https://preproduction-coalition.enrollandpay.com':
      return 'preprod';
    case 'https://coalition.enrollandpay.com':
    case 'https://netelement-coalition.enrollandpay.com':
    case 'https://nabagent.enrollandpay.com':
    case 'https://gspagent.enrollandpay.com':
    case 'https://cocardagent.enrollandpay.com':
    case 'https://coalition.eagleprocessingrewards.com':
      return 'prod';
    default:
      throw Error('Unexpected base URL');
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    AuthModule,
    AdminModule,
    BillingModule,
    DashboardModule,
    LoyaltyProgramModule,
    MembersModule,
    MerchantsModule,
    ChangePasswordModule,
    ToastModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configurationService: ConfigurationService) => () =>
        configurationService.load(
          getEnvironment(),
          assetCacheBusting[getEnvironment() as keyof typeof assetCacheBusting]
        ),
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { LoginResponse } from '../models/login-response.model';
import { ApplicationProfile } from '../models/application-profile.model';
import { ChangePassword } from '../models/change-password.model';
import { ResetPasswordRequest } from '../models/reset-password-request.model';
import { Credentials } from '../models/credentials.model';
import { Resources } from '../models/constants';
import { Representative } from '../models/representative.model';
import { ApiResponse } from '../models/api-response.model';
import { LoginTypes } from '../enums/login-types.enum';
import { BaseApiService } from '@ep/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseApiService {
  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    const json = JSON.stringify(loginRequest);
    return this.post<LoginResponse>('/Login', json, true);
  }

  logout(): Observable<void> {
    return this.get<void>('/Logout', {}, true).pipe(
      tap(() => {
        const savedCredentials = localStorage.getItem(Resources.CredentialsKey);
        if (savedCredentials) {
          const credentials: Credentials = JSON.parse(savedCredentials);

          credentials.jwt = null;
          const updatedCredentialsJSON = JSON.stringify(credentials);

          localStorage.setItem(Resources.CredentialsKey, updatedCredentialsJSON);
        }
      })
    );
  }

  forgotPassword(forgotPasswordRequest: ResetPasswordRequest): Observable<void> {
    const json = JSON.stringify(forgotPasswordRequest);
    return this.post<void>('/ForgotPassword', json, true);
  }

  getApplicationProfile(): Observable<ApplicationProfile> {
    return this.get<ApplicationProfile>('/v2/ApplicationProfile/representative').pipe(
      catchError((err) => {
        console.error('Issue with getting employee profile');
        throw err;
      }),
      map(mapToApplicationProfile)
    );
  }

  changePassword(changePassword: ChangePassword): Observable<void> {
    const json = JSON.stringify(changePassword);
    return this.post<void>('/ChangePassword', json, true);
  }

  confirmPasswordReset(confirmPasswordReset: ChangePassword): Observable<void> {
    const json = JSON.stringify(confirmPasswordReset);
    return this.post<void>('/ConfirmPasswordReset', json, true);
  }

  getRepresentativeByPasswordResetId(passwordResetId: string): Observable<ApiResponse<Representative>> {
    const queryParams = {
      loginType: LoginTypes.Representative,
    };

    return this.get<ApiResponse<Representative>>(`/PasswordReset/${passwordResetId}`, queryParams, true);
  }
}

function mapToApplicationProfile(profile: any): ApplicationProfile {
  return {
    Representative: {
      $id: profile.representative.$id,
      RepresentativeId: profile.representative.representativeId,
      CoalitionId: profile.representative.coalitionId,
      NameFirst: profile.representative.nameFirst,
      NameLast: profile.representative.nameLast,
      EmailAddress: profile.representative.emailAddress,
      PhoneNumber: profile.representative.phoneNumber,
      AccessType: profile.representative.accessType,
      StatusType: profile.representative.statusType,
      Coalition: {
        $id: profile.representative.coalition.$id,
        CoalitionId: profile.representative.coalition.coalitionId,
        Name: profile.representative.coalition.name,
        InternalPortalDomain: profile.representative.coalition.internalPortalDomain,
        MerchantPortalDomain: profile.representative.coalition.merchantPortalDomain,
      },
    },
  };
}

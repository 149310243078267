<div
  class="app-shell-wrapper"
  [ngStyle]="{ 'flex-direction': isMobileScreen ? 'column' : 'row' }"
  data-cy="app-shell-wrapper"
>
  <!-- Mobile Navigation Bar -->
  <div *ngIf="isMobileScreen" class="mobile-dropdown">
    <div class="mobile-header">
      <img
        [src]="
          subdomainType === SubdomainTypes.EagleProcessing
            ? '../../assets/eagle-logo.png'
            : '../../assets/enroll-and-pay-logo.png'
        "
        alt="image"
        class="logo-image"
      />

      <i class="fas fa-bars fs-4 header-icon" (click)="onToggleMobileDropdown()"></i>
    </div>

    <div class="dropdown-content" *ngIf="showMobileDropdown">
      <a class="nav-link" (click)="closeMobileDropdown(); onNavigationIFrame('dashboard')" routerLinkActive="active">
        <div class="nav-link-content">
          <i class="fa-solid fa-house mr-3"></i>
          <span class="nav-label">Dashboard</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.MerchantAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigation('merchants')"
        routerLinkActive="active"
        data-cy="nav-merchants"
      >
        <div class="nav-link-content">
          <i class="fa-solid fa-store mr-3"></i>
          <span class="nav-label">Merchants</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.LoyaltyProgramAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigationIFrame('loyalty-program')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <i class="fa-solid fa-hand-holding-dollar mr-3"></i>
          <span class="nav-label">Loyalty Program</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.Billing)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigationIFrame('billing')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <i class="fa-solid fa-envelope mr-3"></i>
          <span class="nav-label">Billing</span>
        </div>
      </a>
      <a
        *ngIf="shouldShowMenuItem(AccessType.ConsumerAdmin)"
        class="nav-link"
        (click)="closeMobileDropdown(); onNavigationIFrame('members')"
        routerLinkActive="active"
      >
        <div class="nav-link-content">
          <i class="fa-solid fa-users mr-3"></i>
          <span class="nav-label">Members</span>
        </div>
      </a>
      <a class="nav-link" (click)="closeMobileDropdown(); onNavigationIFrame('admin')" routerLinkActive="active">
        <div class="nav-link-content">
          <i class="fa-solid fa-gear mr-3"></i>
          <span class="nav-label">Admin</span>
        </div>
      </a>
    </div>
  </div>

  <!-- Tablet/Desktop Navigation Bar -->
  <div
    *ngIf="!isMobileScreen"
    [ngClass]="{
      'minimized-sidebar': (isSmallScreen && !shouldShowFullWidth) || !shouldShowFullWidth
    }"
  >
    <div class="side-navigation-bar">
      <div class="side-navigation-bar-header">
        <img [src]="getImageSrc()" alt="image" class="logo-image" />
      </div>
      <!-- Your side navigation bar content here -->
      <nav class="nav">
        <a
          class="nav-link"
          (click)="onNavigationIFrame('dashboard')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Dashboard' : ''"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-house mr-3"></i>
            <span class="nav-label">Dashboard</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.MerchantAdmin)"
          class="nav-link"
          (click)="onNavigation('merchants')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Merchants' : ''"
          data-cy="nav-merchants-mobile"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-store mr-3"></i>
            <span class="nav-label">Merchants</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.LoyaltyProgramAdmin)"
          class="nav-link"
          (click)="onNavigationIFrame('loyalty-program')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Loyalty Program' : ''"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-hand-holding-dollar mr-3"></i>
            <span class="nav-label">Loyalty Program</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.Billing)"
          class="nav-link"
          (click)="onNavigationIFrame('billing')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Billing' : ''"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-envelope mr-3"></i>
            <span class="nav-label">Billing</span>
          </div>
        </a>
        <a
          *ngIf="shouldShowMenuItem(AccessType.ConsumerAdmin)"
          class="nav-link"
          (click)="onNavigationIFrame('members')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Members' : ''"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-users mr-3"></i>
            <span class="nav-label">Members</span>
          </div>
        </a>
        <a
          class="nav-link"
          (click)="onNavigationIFrame('admin')"
          routerLinkActive="active"
          [pTooltip]="isSmallScreen ? 'Admin' : ''"
        >
          <div class="nav-link-content">
            <i class="fa-solid fa-gear mr-3"></i>
            <span class="nav-label">Admin</span>
          </div>
        </a>
      </nav>
    </div>
  </div>

  <!-- Main Content -->
  <div class="main-content">
    <app-header (closeClicked)="onCloseClicked()"></app-header>
    <div class="app-content">
      <!-- Your main content, including header and router-outlet -->
      <ep-iframe-manager
        #iframeManager
        id="iframe-manager"
        [iframeWidth]="iframeWidth"
        [iframeHeight]="iframeHeight"
        [ngClass]="{ hidden: isLoading || !shouldShowIframe }"
      ></ep-iframe-manager>
      <div *ngIf="!isLoading && !shouldShowIframe" id="coalition-portal-routing-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-loading-spinner *ngIf="isLoading" [message]="'Loading...'" class="text-center"></app-loading-spinner>
    </div>
  </div>
</div>

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MerchantFormComponent } from '@app/areas/merchants/forms/merchant-form/merchant-form.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MessageService, SharedModule } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, startWith, Subscription, tap } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { MerchantFormService } from '@app/areas/merchants/services/merchant-form.service';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { CreateEnrollmentRequest } from '@app/resources/services/enrollment.service';
import { MerchantsApiService } from '@app/areas/merchants/services/merchants.api.service';
import { MerchantEnrollmentConfirmationComponent } from '@app/areas/merchants/components/merchant-enrollment-confirmation/merchant-enrollment-confirmation.component';
import { ConfigurationService } from '@ep/shared';

@Component({
  selector: 'app-new-merchant',
  templateUrl: './new-merchant.component.html',
  styleUrls: ['./new-merchant.component.scss'],
  standalone: true,
  imports: [
    MerchantFormComponent,
    ButtonModule,
    CardModule,
    SharedModule,
    TooltipModule,
    AsyncPipe,
    NgIf,
    MerchantEnrollmentConfirmationComponent,
  ],
})
export class NewMerchantComponent implements OnInit, OnDestroy {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly merchantApiService = inject(MerchantsApiService);
  private readonly merchantFormService = inject(MerchantFormService);
  private readonly messageService = inject(MessageService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  protected isSaveDisabled$ = this.merchantFormService.getForm().statusChanges.pipe(
    startWith(this.merchantFormService.getForm().status),
    map((status) => {
      return status !== 'VALID';
    })
  );

  protected merchantEnrollmentLink: string | null = null;
  protected isSaving = false;
  private subs = new Subscription();

  ngOnInit() {
    this.merchantFormService.initializeForm();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected goBackToMerchantsTable() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  protected saveMerchant() {
    if (this.merchantFormService.getForm().valid) {
      this.isSaving = true;

      const representative = this.applicationProfileService.getApplicationProfile().Representative;
      const merchantFormValue = this.merchantFormService.getForm().getRawValue();
      const createEnrollmentRequest: CreateEnrollmentRequest = {
        CoalitionId: representative.CoalitionId,
        SalesRepresentativeId: representative.RepresentativeId,
        MerchantEmail: merchantFormValue.Email!,
        PricingInformation: {
          Base: merchantFormValue.BasePrice!,
          PerCampaign: 0, // Default value. Removed from merchant form until further notice.
          PerDevice: 0, // Default value. Removed from merchant form until further notice.
          PerLocation: 0, // Default value. Removed from merchant form until further notice.
          PerSMS: merchantFormValue.PricePerSms!,
          MerchantPaymentType: merchantFormValue.PaymentType,
          PrepaidCampaigns: 0, // Default value. Removed from merchant form until further notice.
        },
      };

      this.merchantApiService
        .enrollMerchant(createEnrollmentRequest)
        .pipe(
          catchError((err) => {
            this.postEnrollmentFailureMessage();
            return of({ error: true });
          })
        )
        .subscribe((result: any) => {
          if (result && !result.error) {
            this.messageService.add({
              severity: 'success',
              summary: 'SUCCESS: Enroll Merchant',
              detail: 'Successfully enrolled a new merchant',
            });

            const enrollmentId = result.enrollmentId;
            const merchantUrl =
              representative.Coalition.MerchantPortalDomain ?? this.configurationService.merchantPortalUrl;
            if (merchantUrl === '' || merchantUrl === null) {
              console.error('Missing merchant portal domain');
              this.merchantApiService
                .sendContactSupportEmail({
                  RepresentativeId: representative.RepresentativeId,
                  Body: `Could not create a enrollment link for a merchant. Missing a merchant portal domain for ${representative.Coalition.Name}.`,
                })
                .pipe(
                  catchError(() => {
                    console.error('Could not send contact support emails.');
                    return of(null);
                  }),
                  tap(() => {
                    this.router.navigate(['../'], { relativeTo: this.route });
                  })
                )
                .subscribe();
            } else {
              this.isSaving = false;
              this.merchantEnrollmentLink = `${merchantUrl}/enroll/${enrollmentId}`;
            }
          } else {
            this.postEnrollmentFailureMessage();
            this.isSaving = false;
          }
        });
    }
  }

  private postEnrollmentFailureMessage() {
    this.messageService.add({
      severity: 'error',
      summary: 'ERROR: Enroll Merchant',
      detail: 'Something went wrong while enrolling a new merchant',
    });
  }
}

import { Injectable } from '@angular/core';
import { Resources } from '@app/@shared/models/constants';
import { Credentials } from '@app/@shared/models/credentials.model';
import jwt_decode from 'jwt-decode';
import { AccessType } from '@app/@shared/enums/access-types.enum';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private accessTypes: AccessType[] = [];

  constructor() {
    const savedCredentials =
      sessionStorage.getItem(Resources.CredentialsKey) || localStorage.getItem(Resources.CredentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      if (this._credentials) {
        this.initializeAccessTypes(this._credentials.jwt);
      }
    }
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  isAuthenticated(): boolean {
    return !!this.credentials?.jwt && !this.isTokenExpired(this.credentials.jwt);
  }

  hasAccessAny(accessTypes: AccessType[]): boolean {
    return this.accessTypes.some((accessType) => accessTypes.includes(accessType));
  }

  setCredentials(credentials?: Credentials): void {
    this._credentials = credentials || null;

    if (credentials) {
      localStorage.setItem(Resources.CredentialsKey, JSON.stringify(credentials));

      // Decode token and set access types.
      const tokenInfo = this.getDecodedAccessToken(credentials.jwt);
      const expireDate = tokenInfo.exp; // token expiration date.

      const accessTypes: AccessType[] = tokenInfo.RepresentativeAccessType.split(',')
        .map((accessType: string) => accessType.trim())
        .map((accessType: string) => this.mapToAccessType(accessType));

      this.setAccessTypes(accessTypes);
    }
  }

  private isTokenExpired(token: string): boolean {
    const decoded: any = this.getDecodedAccessToken(token);
    if (!decoded.exp) {
      return true; // If there's no expiry information, assume it's expired.
    }

    const expiryDateInMs = decoded.exp * 1000;
    const currentDateInMs = Date.now();

    return currentDateInMs >= expiryDateInMs;
  }

  private setAccessTypes(accessTypes: AccessType[]): void {
    this.accessTypes = accessTypes;
  }

  private getDecodedAccessToken(token: string | null): any {
    try {
      if (!token) {
        return null;
      }
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private mapToAccessType(accessType: string): AccessType {
    switch (accessType) {
      case 'MerchantAdmin':
        return AccessType.MerchantAdmin;
      case 'LoyaltyProgramAdmin':
        return AccessType.LoyaltyProgramAdmin;
      case 'Billing':
        return AccessType.Billing;
      case 'RepresentativeAdmin':
        return AccessType.RepresentativeAdmin;
      case 'OfferAdmin':
        return AccessType.OfferAdmin;
      case 'ConsumerAdmin':
        return AccessType.ConsumerAdmin;
      case 'TeamAdmin':
        return AccessType.TeamAdmin;
      default:
        throw new Error(`Unrecognized access type: ${accessType}`);
    }
  }

  private initializeAccessTypes(jwt: string | null): void {
    const tokenInfo = this.getDecodedAccessToken(jwt);
    if (tokenInfo) {
      const accessTypes: AccessType[] = tokenInfo.RepresentativeAccessType.split(',')
        .map((accessType: string) => accessType.trim())
        .map((accessType: string) => this.mapToAccessType(accessType));

      this.setAccessTypes(accessTypes);
    }
  }
}

import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MerchantPaymentTypes } from '@shared/enums';

export interface MerchantFormControls {
  Email: FormControl<string | null>;
  PaymentType: FormControl<MerchantPaymentTypes>;
  BasePrice: FormControl<number | null>;
  PricePerSms: FormControl<number | null>;
}

export type MerchantFormGroup = FormGroup<MerchantFormControls>;

@Injectable()
export class MerchantFormService {
  private readonly fb = inject(NonNullableFormBuilder);

  private merchantForm: MerchantFormGroup = this.fb.group<MerchantFormControls>({
    Email: this.fb.control<string | null>(null, { validators: [Validators.required, Validators.email] }),
    PaymentType: this.fb.control<MerchantPaymentTypes>(
      { disabled: true, value: MerchantPaymentTypes.Paid },
      { validators: [Validators.required] }
    ),
    BasePrice: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
    PricePerSms: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
  });

  getForm() {
    return this.merchantForm;
  }

  initializeForm() {
    this.merchantForm.reset();
  }
}

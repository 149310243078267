import { Component, OnInit, HostListener, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { IframeEventTypes } from '@app/@shared/enums/iframe-event-types.enum';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { CredentialsService } from '@app/auth';
import { environment } from '@env/environment';
import { ConfigurationService, IframeManagerComponent, isNotNullOrUndefined } from '@ep/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, debounceTime, filter, skip, Subscription, tap } from 'rxjs';
import { WindowService } from '@app/resources/services';

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  private readonly windowService = inject(WindowService);
  @ViewChild('iframeManager', { static: true }) iframeManager!: IframeManagerComponent;

  // Component State.
  isLoading: boolean = false;
  shouldShowIframe: boolean = false;
  iframeWidth: string = '0%';
  iframeHeight: string = '0%';
  showMobileDropdown: boolean = false;

  // Application Data.
  AccessType = AccessType;
  applicationProfile!: ApplicationProfile;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  subs = new Subscription();

  constructor(
    private router: Router,
    private applicationProfileService: ApplicationProfileService,
    private iframeManagerService: IframeManagerService,
    private credentialsService: CredentialsService,
    private configurationService: ConfigurationService,
    private subdomainService: SubdomainService
  ) {}

  private _resize$ = new BehaviorSubject<boolean>(false);
  readonly resize$ = this._resize$.asObservable().pipe(filter(isNotNullOrUndefined));

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this._resize$.next(true);
  }

  ngOnInit(): void {
    this.subs.add(
      this.resize$
        .pipe(
          debounceTime(300),
          tap(() => {
            this.windowService.checkScreenSize();

            document.documentElement.style.setProperty('--screenHeight', `${window.innerHeight}px`);
          })
        )
        .subscribe()
    );

    this.subdomainType = this.subdomainService.getDomain();
    this.windowService.checkScreenSize();
    this.subscribeToApplicationProfile();
    this.subscribeToIframeService();
    this.authenticateAndCheckRoute();

    // Subscribe to the iframe source changes.
    this.iframeManagerService.iframeSrc$.pipe(untilDestroyed(this)).subscribe((src) => {
      this.iframeManager.updateSanitizedUrl(src);
    });

    this.iframeManagerService
      .onMessageReceived()
      .pipe(
        untilDestroyed(this),
        filter(
          (event) =>
            event.origin ===
              (this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy) &&
            event.data === IframeEventTypes.LegacyAppLoaded
        )
      )
      .subscribe(() => {
        this.handleLegacyAppMessage();
      });
  }

  get isMobileScreen() {
    return this.windowService.isMobileScreen;
  }

  get isSmallScreen() {
    return this.windowService.isSmallScreen;
  }

  get shouldShowFullWidth() {
    return this.windowService.shouldShowFullWidth;
  }

  set shouldShowFullWidth(showFullWidth: boolean) {
    this.windowService.setShowFullWidth(showFullWidth);
  }

  onCloseClicked(): void {
    this.shouldShowFullWidth = !this.shouldShowFullWidth;
  }

  onNavigation(resource: string) {
    this.iframeManagerService.setShouldShowIframe(false);
    this.router.navigateByUrl(`/${this.applicationProfile.Representative.CoalitionId}/${resource}`);
  }

  onNavigationIFrame(primaryPath: string, primaryPathId?: string): void {
    this.updateIframeAndNavigate(primaryPath, primaryPathId);
  }

  onToggleMobileDropdown(): void {
    this.showMobileDropdown = !this.showMobileDropdown;
  }

  closeMobileDropdown(): void {
    this.showMobileDropdown = false;
  }

  shouldShowMenuItem(accessType: AccessType): boolean {
    return this.credentialsService.hasAccessAny([accessType]);
  }

  getImageSrc(): string {
    const prefix = (this.isSmallScreen && !this.shouldShowFullWidth) || !this.shouldShowFullWidth ? 'small-' : '';
    const suffix = this.subdomainType === SubdomainTypes.EagleProcessing ? 'eagle-logo.png' : 'enroll-and-pay-logo.png';
    return `../../assets/${prefix}${suffix}`;
  }

  private subscribeToApplicationProfile(): void {
    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
      });
  }

  private subscribeToIframeService(): void {
    this.iframeManagerService.shouldShowIframe$
      .pipe(untilDestroyed(this), skip(1))
      .subscribe((shouldShowIframe: boolean) => {
        this.adjustIframeDimensions(shouldShowIframe);
        this.isLoading = false;
      });
  }

  private adjustIframeDimensions(shouldShow: boolean): void {
    this.shouldShowIframe = shouldShow;
    const dimension = shouldShow ? '100%' : '0%';
    this.iframeWidth = dimension;
    this.iframeHeight = dimension;
  }

  private updateIframeAndNavigate(primaryPath: string, primaryPathId?: string): void {
    const coalitionId = this.applicationProfile.Representative.CoalitionId;
    const iframeSrc = this.iframeManagerService.generateIframeSrc(
      this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy,
      coalitionId,
      primaryPath,
      primaryPathId
    );
    if (iframeSrc) {
      this.iframeManagerService.setIframeSrc(iframeSrc);
      const path = `${coalitionId}/${primaryPath}${primaryPathId ? '/' + primaryPathId : ''}`;
      this.router.navigate([path]);
      this.iframeManagerService.setShouldShowIframe(true);
    }
  }

  private handleLegacyAppMessage(): void {
    this.checkCurrentRoute();
    this.isLoading = false;
  }

  private authenticateAndCheckRoute(): void {
    this.isLoading = true;
    this.authenticateLegacy();
  }

  private authenticateLegacy(): Promise<void> {
    return new Promise((resolve) => {
      const credentials = this.credentialsService.credentials;
      const iframeSrc = `${
        this.configurationService.coalitionPortalUrlLegacy ?? environment.coalitionPortalUrlLegacy
      }/#Impersonate/${credentials?.jwt}/${credentials?.clientAccessId}`;
      this.iframeManagerService.setIframeSrc(iframeSrc);
      resolve();
    });
  }

  private checkCurrentRoute(): void {
    const currentRoute = this.router.url;
    if (currentRoute === '/') {
      this.onNavigationIFrame('dashboard');
    } else {
      const segments = currentRoute.split('/');
      this.navigateOnSegments(segments);
    }
  }

  /**
   * We'll have to manually update this as we port over more resources to the new Angular app.
   * If the url segment contains a route that is implemented in Angular, route to the new Angular
   * app instead of loading the legacy iframe.
   * @param segments segments of the current url broken up by "/"
   * @private
   */
  private navigateOnSegments(segments: string[]) {
    if (!segments.includes('merchants')) {
      this.onNavigationIFrame(segments[2], segments[3]);
    } else {
      const path = segments.slice(2).join('/');
      this.onNavigation(path);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  coalitionId!: string | null;
  isLoading: boolean = false;

  iframeSrc: string = '';

  constructor(private router: Router, private applicationProfileService: ApplicationProfileService) {}

  applicationProfile!: ApplicationProfile;

  ngOnInit() {
    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
      });
  }

  navigateToPath(path: string) {
    this.router.navigate([`${this.applicationProfile.Representative.CoalitionId}/admin/${path}`]);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ConfigurationService } from '@ep/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IframeManagerService {
  private messageSubject = new Subject<MessageEvent>();
  private iframeSrcSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private shouldShowIframeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private configurationService: ConfigurationService) {
    window.addEventListener('message', (event) => {
      this.messageSubject.next(event);
    });
  }

  get iframeSrc$(): Observable<string> {
    return this.iframeSrcSubject.asObservable();
  }

  get shouldShowIframe$(): Observable<boolean> {
    return this.shouldShowIframeSubject.asObservable();
  }

  onMessageReceived(): Observable<MessageEvent> {
    return this.messageSubject.asObservable();
  }

  setIframeSrc(src: string): void {
    this.iframeSrcSubject.next(src);
  }

  setShouldShowIframe(shouldShowIframe: boolean): void {
    this.shouldShowIframeSubject.next(shouldShowIframe);
  }

  generateIframeSrc(
    coalitionPortalUrlLegacy: string,
    coalitionId: number,
    primaryPath: string,
    primaryPathId?: string
  ): string {
    const path = mapPathToIframeSrc(primaryPath, primaryPathId);
    if (!path) {
      return '';
    }

    let iframeSrc = `${coalitionPortalUrlLegacy}/#L${coalitionId}/${path}`;
    if (primaryPathId) {
      iframeSrc = `${iframeSrc}/${primaryPathId}`;
    }

    return iframeSrc;
  }
}

function mapPathToIframeSrc(path: string, id?: string) {
  if (path.includes('dashboard')) {
    return 'Dashboard';
  } else if (path.includes('merchants')) {
    return `Merchants/${id}`;
  } else if (path.includes('loyalty-program')) {
    return 'LoyaltyProgram';
  } else if (path.includes('billing')) {
    return 'BillingCycles';
  } else if (path.includes('members')) {
    return 'Consumers';
  } else if (path.includes('admin')) {
    return 'Admin';
  } else {
    throw new Error('Issue with mapping path to iframe source');
  }
}

const pathToIframeSrcMap: { [key: string]: string } = {
  dashboard: 'Dashboard',
  ['merchants/new']: 'Merchants/New',
  ['merchants/edit']: 'Merchants/',
  'loyalty-program': 'LoyaltyProgram',
  billing: 'BillingCycles',
  members: 'Consumers',
  admin: 'Admin',
};
